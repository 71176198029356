// File: src/components/Location/LocationList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllLocations, deleteLocation } from '../../services/api';
import { useSnackbar } from 'notistack';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function LocationList() {
  const [locations, setLocations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await getAllLocations();
        setLocations(response.data);
      } catch (error) {
        enqueueSnackbar('Failed to fetch locations', { variant: 'error' });
        console.error(error);
      }
    };

    fetchLocations();
  }, [enqueueSnackbar]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      try {
        await deleteLocation(id);
        enqueueSnackbar('Location deleted successfully', { variant: 'success' });
        setLocations((prev) => prev.filter((location) => location.id !== id));
      } catch (error) {
        enqueueSnackbar('Failed to delete location', { variant: 'error' });
        console.error(error);
      }
    }
  };

  if (isMobile) {
    return (
      <Paper sx={{ marginTop: 4, padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Locations
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/locations/new"
          sx={{ marginBottom: 2 }}
        >
          Add New Location
        </Button>
        <Grid container spacing={2}>
          {locations.length > 0 ? (
            locations.map((location) => (
              <Grid item xs={12} key={location.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{location.name}</Typography>
                    <Typography>Description: {location.description}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      component={Link}
                      to={`/locations/edit/${location.id}`}
                      color="primary"
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleDelete(location.id)}
                      color="secondary"
                    >
                      Delete
                    </Button>
                    <Button
                      size="small"
                      component={Link}
                      to={`/locations/detail/${location.id}`}
                      color="info"
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography align="center">No Locations Found</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }

  // Desktop and Tablet View
  return (
    <TableContainer component={Paper} sx={{ marginTop: 4, padding: 2, overflowX: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Locations
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/locations/new"
        sx={{ marginBottom: 2 }}
      >
        Add New Location
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>ID</strong></TableCell>
            <TableCell><strong>Name</strong></TableCell>
            <TableCell><strong>Description</strong></TableCell>
            <TableCell><strong>Actions</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations.length > 0 ? (
            locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell>{location.id}</TableCell>
                <TableCell>{location.name}</TableCell>
                <TableCell>{location.description}</TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/locations/edit/${location.id}`}
                    color="primary"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(location.id)}
                    color="secondary"
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={`/locations/detail/${location.id}`}
                    color="info"
                  >
                    <Button variant="text">View</Button>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No Locations Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LocationList;
