// File: src/components/Search/SearchBar.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Autocomplete, CircularProgress, Box, Typography, Avatar } from '@mui/material';
import { searchProducts } from '../../services/api';
import debounce from 'lodash.debounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function SearchBar() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Debounced search function to prevent excessive API calls
  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (query) => {
        if (query.length === 0) {
          setOptions([]);
          setLoading(false);
          return;
        }
        try {
          const response = await searchProducts(query);
          setOptions(response.data);
        } catch (error) {
          console.error('Search failed:', error);
        } finally {
          setLoading(false);
        }
      }, 300),
    []
  );

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      setLoading(false);
      return undefined;
    }

    setLoading(true);
    debouncedSearch(inputValue);

    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  const handleOptionSelect = (event, value) => {
    if (value && value.id) {
      // Navigate to the product detail page
      navigate(`/products/detail/${value.id}`);
    }
  };

  return (
    <Autocomplete
      id="product-search"
      sx={{ width: isMobile ? '100%' : 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={handleOptionSelect}
      getOptionLabel={(option) => option.name || ''}
      options={options}
      loading={loading}
      noOptionsText="No products found"
      renderOption={(props, option) => {
        // Calculate total quantity across all locations
        const totalQuantity = option.stockDetails.reduce((sum, stock) => sum + stock.quantity, 0);
        // Get unique location names
        const locationNames = option.stockDetails.map((stock) => stock.locationName).join(', ');

        return (
          <li {...props} key={option.id}>
            <Box display="flex" alignItems="center">
              <Avatar
                src={option.imageUrl}
                alt={option.name}
                variant="square"
                sx={{ width: 56, height: 56, marginRight: 2 }}
              />
              <Box>
                <Typography variant="subtitle1" component="div">
                  {option.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${option.price.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Stock: {totalQuantity}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Location: {locationNames}
                </Typography>
              </Box>
            </Box>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Products"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchBar;
