// File: src/components/Product/ProductForm.js
import React, { useState, useEffect } from 'react';
import {
  createProduct,
  getProductById,
  updateProduct,
} from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Box,
} from '@mui/material';

function ProductForm() {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    imageUrl: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchProduct = async () => {
      if (id) {
        try {
          const response = await getProductById(id);
          setProduct(response.data);
        } catch (error) {
          enqueueSnackbar('Failed to fetch product details', { variant: 'error' });
          console.error(error);
        }
      }
    };

    fetchProduct();
  }, [id, enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateProduct(id, product);
        enqueueSnackbar('Product updated successfully', { variant: 'success' });
      } else {
        await createProduct(product);
        enqueueSnackbar('Product created successfully', { variant: 'success' });
      }
      navigate('/products');
    } catch (error) {
      enqueueSnackbar('Failed to submit product', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 } }}>
      <Typography variant="h6" gutterBottom>
        {id ? 'Edit Product' : 'Add New Product'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              required
              label="Product Name"
              name="name"
              value={product.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={product.description}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* Price */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Price ($)"
              name="price"
              type="number"
              value={product.price}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              inputProps={{ min: '0', step: '0.01' }}
            />
          </Grid>
          {/* Image URL */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Image URL"
              name="imageUrl"
              value={product.imageUrl}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* Submit and Cancel Buttons */}
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Update Product' : 'Create Product'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/products')}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default ProductForm;
