// File: src/components/Stock/StockList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getAllStocks,
  deleteStock,
  getProductById,
  getLocationById,
} from '../../services/api';
import { useSnackbar } from 'notistack';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function StockList() {
  const [stocks, setStocks] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [productMap, setProductMap] = useState({});
  const [locationMap, setLocationMap] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const response = await getAllStocks();
        setStocks(response.data);

        // Extract unique product and location IDs
        const productIds = [
          ...new Set(response.data.map((stock) => stock.productId)),
        ];
        const locationIds = [
          ...new Set(response.data.map((stock) => stock.locationId)),
        ];

        // Fetch products
        const productPromises = productIds.map((id) =>
          getProductById(id).then((res) => ({ id, name: res.data.name }))
        );
        const products = await Promise.all(productPromises);
        const productData = {};
        products.forEach((product) => {
          productData[product.id] = product.name;
        });
        setProductMap(productData);

        // Fetch locations
        const locationPromises = locationIds.map((id) =>
          getLocationById(id).then((res) => ({ id, name: res.data.name }))
        );
        const locations = await Promise.all(locationPromises);
        const locationData = {};
        locations.forEach((location) => {
          locationData[location.id] = location.name;
        });
        setLocationMap(locationData);
      } catch (error) {
        enqueueSnackbar('Failed to fetch stocks', { variant: 'error' });
        console.error(error);
      }
    };

    fetchStocks();
  }, [enqueueSnackbar]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this stock entry?')) {
      try {
        await deleteStock(id);
        enqueueSnackbar('Stock entry deleted successfully', { variant: 'success' });
        setStocks((prev) => prev.filter((stock) => stock.id !== id));
      } catch (error) {
        enqueueSnackbar('Failed to delete stock entry', { variant: 'error' });
        console.error(error);
      }
    }
  };

  if (isMobile) {
    return (
      <Paper sx={{ marginTop: 4, padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Stocks
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/stocks/new"
          sx={{ marginBottom: 2 }}
        >
          Add New Stock
        </Button>
        <Grid container spacing={2}>
          {stocks.length > 0 ? (
            stocks.map((stock) => (
              <Grid item xs={12} key={stock.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Stock ID: {stock.id}</Typography>
                    <Typography>Product: {productMap[stock.productId] || 'Loading...'}</Typography>
                    <Typography>Location: {locationMap[stock.locationId] || 'Loading...'}</Typography>
                    <Typography>Quantity: {stock.quantity}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      component={Link}
                      to={`/stocks/edit/${stock.id}`}
                      color="primary"
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleDelete(stock.id)}
                      color="secondary"
                    >
                      Delete
                    </Button>
                    <Button
                      size="small"
                      component={Link}
                      to={`/stocks/detail/${stock.id}`}
                      color="info"
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography align="center">No Stocks Found</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }

  // Desktop and Tablet View
  return (
    <TableContainer component={Paper} sx={{ marginTop: 4, padding: 2, overflowX: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Stocks
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/stocks/new"
        sx={{ marginBottom: 2 }}
      >
        Add New Stock
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>ID</strong></TableCell>
            <TableCell><strong>Product</strong></TableCell>
            <TableCell><strong>Location</strong></TableCell>
            <TableCell><strong>Quantity</strong></TableCell>
            <TableCell><strong>Actions</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stocks.length > 0 ? (
            stocks.map((stock) => (
              <TableRow key={stock.id}>
                <TableCell>{stock.id}</TableCell>
                <TableCell>
                  {productMap[stock.productId] || 'Loading...'}
                </TableCell>
                <TableCell>
                  {locationMap[stock.locationId] || 'Loading...'}
                </TableCell>
                <TableCell>{stock.quantity}</TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/stocks/edit/${stock.id}`}
                    color="primary"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(stock.id)}
                    color="secondary"
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={`/stocks/detail/${stock.id}`}
                    color="info"
                  >
                    <Button variant="text">View</Button>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No Stocks Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StockList;
