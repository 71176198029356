// File: src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

import ProductList from './components/Product/ProductList';
import ProductForm from './components/Product/ProductForm';
import ProductDetail from './components/Product/ProductDetail';
import LocationList from './components/Location/LocationList';
import LocationForm from './components/Location/LocationForm';
import LocationDetail from './components/Location/LocationDetail';
import StockList from './components/Stock/StockList';
import StockForm from './components/Stock/StockForm';
import StockDetail from './components/Stock/StockDetail';
import SearchBar from './components/search/SearchBar';
import Footer from './components/Footer/Footer'; // Import Footer

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navigationLinks = [
    { name: 'Products', path: '/products' },
    { name: 'Locations', path: '/locations' },
    { name: 'Stocks', path: '/stocks' },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        SHRI SENTHILMURUGAN AUTO SPARES
      </Typography>
      <List>
        {navigationLinks.map((item) => (
          <ListItem button key={item.name} component={Link} to={item.path}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <SnackbarProvider maxSnack={3}>
      <Router>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh', // Ensures the footer stays at the bottom
          }}
        >
          <AppBar position="static">
            <Toolbar>
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                SHRI SENTHILMURUGAN AUTO SPARES
              </Typography>
              {!isMobile && (
                <>
                  <Box sx={{ marginRight: 2 }}>
                    <SearchBar />
                  </Box>
                  {navigationLinks.map((item) => (
                    <Button key={item.name} color="inherit" component={Link} to={item.path}>
                      {item.name}
                    </Button>
                  ))}
                </>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
            }}
          >
            {drawer}
          </Drawer>
          <Container component="main" maxWidth="lg" sx={{ flexGrow: 1, paddingY: 4 }}>
            <Routes>
              {/* Products Routes */}
              <Route path="/products" element={<ProductList />} />
              <Route path="/products/new" element={<ProductForm />} />
              <Route path="/products/edit/:id" element={<ProductForm />} />
              <Route path="/products/detail/:id" element={<ProductDetail />} />

              {/* Locations Routes */}
              <Route path="/locations" element={<LocationList />} />
              <Route path="/locations/new" element={<LocationForm />} />
              <Route path="/locations/edit/:id" element={<LocationForm />} />
              <Route path="/locations/detail/:id" element={<LocationDetail />} />

              {/* Stocks Routes */}
              <Route path="/stocks" element={<StockList />} />
              <Route path="/stocks/new" element={<StockForm />} />
              <Route path="/stocks/edit/:id" element={<StockForm />} />
              <Route path="/stocks/detail/:id" element={<StockDetail />} />

              {/* Default Route */}
              <Route path="/" element={<Navigate to="/products" replace />} />
              <Route path="*" element={<Navigate to="/products" replace />} />
            </Routes>
          </Container>
          <Footer /> {/* Add Footer here */}
        </Box>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
