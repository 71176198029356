// File: src/services/api.js
import axios from 'axios';

// Dynamically set the API base URL based on environment
const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://your-production-domain.com/api'
    : 'http://localhost:8080/api';

// Products
export const getAllProducts = () => axios.get(`${API_BASE_URL}/products`);
export const getProductById = (id) => axios.get(`${API_BASE_URL}/products/${id}`);
export const createProduct = (product) => axios.post(`${API_BASE_URL}/products`, product);
export const updateProduct = (id, product) => axios.put(`${API_BASE_URL}/products/${id}`, product);
export const deleteProduct = (id) => axios.delete(`${API_BASE_URL}/products/${id}`);
export const searchProducts = (query) =>
  axios.get(`${API_BASE_URL}/products/search?q=${encodeURIComponent(query)}`);

// Locations
export const getAllLocations = () => axios.get(`${API_BASE_URL}/locations`);
export const getLocationById = (id) => axios.get(`${API_BASE_URL}/locations/${id}`);
export const createLocation = (location) => axios.post(`${API_BASE_URL}/locations`, location);
export const updateLocation = (id, location) => axios.put(`${API_BASE_URL}/locations/${id}`, location);
export const deleteLocation = (id) => axios.delete(`${API_BASE_URL}/locations/${id}`);

// Stocks
export const getAllStocks = () => axios.get(`${API_BASE_URL}/stocks`);
export const getStockById = (id) => axios.get(`${API_BASE_URL}/stocks/${id}`);
export const getStocksByProductId = (productId) =>
  axios.get(`${API_BASE_URL}/stocks/product/${productId}`);
export const getStocksByLocationId = (locationId) =>
  axios.get(`${API_BASE_URL}/stocks/location/${locationId}`);
export const createStock = (stock) => axios.post(`${API_BASE_URL}/stocks`, stock);
export const updateStock = (id, stock) => axios.put(`${API_BASE_URL}/stocks/${id}`, stock);
export const deleteStock = (id) => axios.delete(`${API_BASE_URL}/stocks/${id}`);
