// File: src/components/Footer/Footer.js

import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 2, // Vertical padding
        px: 2, // Horizontal padding
        mt: 'auto', // Pushes the footer to the bottom
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        textAlign: 'center', // Centers the text
      }}
    >
      <Typography variant="body2" color="text.secondary">
        Website was Developed by Full Stack Developer{' '}
        <Box
          component="span"
          sx={{
            fontWeight: 'bold',
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Arunachalam
        </Box>
        .
      </Typography>
    </Box>
  );
}

export default Footer;
