// File: src/components/Location/LocationDetail.js
import React, { useEffect, useState } from 'react';
import { getLocationById } from '../../services/api';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Box,
} from '@mui/material';

function LocationDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await getLocationById(id);
        setLocation(response.data);
      } catch (error) {
        enqueueSnackbar('Failed to fetch location details', { variant: 'error' });
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, [id, enqueueSnackbar]);

  if (loading) {
    return (
      <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!location) {
    return (
      <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <Typography variant="h6">Location not found.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/locations')}
          sx={{ marginTop: 2 }}
        >
          Back to Locations
        </Button>
      </Paper>
    );
  }

  return (
    <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 } }}>
      <Typography variant="h4" gutterBottom>
        {location.name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Description:
          </Typography>
          <Typography variant="body1">{location.description}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/locations"
            sx={{ marginTop: { xs: 2, sm: 0 } }}
          >
            Back to Locations
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default LocationDetail;
