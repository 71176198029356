// File: src/components/Stock/StockDetail.js
import React, { useEffect, useState } from 'react';
import { getStockById, getProductById, getLocationById } from '../../services/api';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Box,
} from '@mui/material';

function StockDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [stock, setStock] = useState(null);
  const [productName, setProductName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStockDetails = async () => {
      try {
        const stockResponse = await getStockById(id);
        setStock(stockResponse.data);

        const [productResponse, locationResponse] = await Promise.all([
          getProductById(stockResponse.data.productId),
          getLocationById(stockResponse.data.locationId),
        ]);

        setProductName(productResponse.data.name);
        setLocationName(locationResponse.data.name);
      } catch (error) {
        enqueueSnackbar('Failed to fetch stock details', { variant: 'error' });
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStockDetails();
  }, [id, enqueueSnackbar]);

  if (loading) {
    return (
      <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!stock) {
    return (
      <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <Typography variant="h6">Stock entry not found.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/stocks')}
          sx={{ marginTop: 2 }}
        >
          Back to Stocks
        </Button>
      </Paper>
    );
  }

  return (
    <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 } }}>
      <Typography variant="h4" gutterBottom>
        Stock Entry #{stock.id}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Product:
          </Typography>
          <Typography variant="body1">{productName}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Location:
          </Typography>
          <Typography variant="body1">{locationName}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Quantity:
          </Typography>
          <Typography variant="body1">{stock.quantity}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/stocks"
            sx={{ marginTop: { xs: 2, sm: 0 } }}
          >
            Back to Stocks
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default StockDetail;
