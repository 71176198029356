// File: src/components/Product/ProductDetail.js
import React, { useEffect, useState } from 'react';
import { getProductById } from '../../services/api';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Box,
} from '@mui/material';

function ProductDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getProductById(id);
        setProduct(response.data);
      } catch (error) {
        enqueueSnackbar('Failed to fetch product details', { variant: 'error' });
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id, enqueueSnackbar]);

  if (loading) {
    return (
      <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!product) {
    return (
      <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <Typography variant="h6">Product not found.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/products')}
          sx={{ marginTop: 2 }}
        >
          Back to Products
        </Button>
      </Paper>
    );
  }

  return (
    <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 } }}>
      <Typography variant="h4" gutterBottom>
        {product.name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box
            component="img"
            src={product.imageUrl}
            alt={product.name}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 2,
              objectFit: 'cover',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6" gutterBottom>
            Description:
          </Typography>
          <Typography variant="body1" paragraph>
            {product.description}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Price:
          </Typography>
          <Typography variant="body1">${Number(product.price).toFixed(2)}</Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/products"
            sx={{ marginTop: 2 }}
          >
            Back to Products
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProductDetail;
