// File: src/components/Stock/StockForm.js
import React, { useState, useEffect } from 'react';
import {
  createStock,
  getStockById,
  updateStock,
  getAllProducts,
  getAllLocations,
} from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from '@mui/material';

function StockForm() {
  const [stock, setStock] = useState({
    productId: '',
    locationId: '',
    quantity: '',
  });
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const [productsResponse, locationsResponse] = await Promise.all([
          getAllProducts(),
          getAllLocations(),
        ]);
        setProducts(productsResponse.data);
        setLocations(locationsResponse.data);
      } catch (error) {
        enqueueSnackbar('Failed to fetch products or locations', { variant: 'error' });
        console.error(error);
      }
    };

    fetchDropdownData();
  }, [enqueueSnackbar]);

  useEffect(() => {
    const fetchStock = async () => {
      if (id) {
        try {
          const response = await getStockById(id);
          setStock(response.data);
        } catch (error) {
          enqueueSnackbar('Failed to fetch stock details', { variant: 'error' });
          console.error(error);
        }
      }
    };

    fetchStock();
  }, [id, enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStock((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateStock(id, stock);
        enqueueSnackbar('Stock updated successfully', { variant: 'success' });
      } else {
        await createStock(stock);
        enqueueSnackbar('Stock created successfully', { variant: 'success' });
      }
      navigate('/stocks');
    } catch (error) {
      enqueueSnackbar('Failed to submit stock', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 } }}>
      <Typography variant="h6" gutterBottom>
        {id ? 'Edit Stock Entry' : 'Add New Stock Entry'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Product Selection */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel id="product-label">Product</InputLabel>
              <Select
                labelId="product-label"
                label="Product"
                name="productId"
                value={stock.productId}
                onChange={handleChange}
              >
                {products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Location Selection */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                labelId="location-label"
                label="Location"
                name="locationId"
                value={stock.locationId}
                onChange={handleChange}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Quantity */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Quantity"
              name="quantity"
              type="number"
              value={stock.quantity}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              inputProps={{ min: '0', step: '1' }}
            />
          </Grid>
          {/* Submit and Cancel Buttons */}
          <Grid item xs={12} sm={6} container alignItems="center">
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Update Stock' : 'Create Stock'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/stocks')}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default StockForm;
