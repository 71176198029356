// File: src/components/Location/LocationForm.js
import React, { useState, useEffect } from 'react';
import {
  createLocation,
  getLocationById,
  updateLocation,
} from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Box,
} from '@mui/material';

function LocationForm() {
  const [location, setLocation] = useState({
    name: '',
    description: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchLocation = async () => {
      if (id) {
        try {
          const response = await getLocationById(id);
          setLocation(response.data);
        } catch (error) {
          enqueueSnackbar('Failed to fetch location details', { variant: 'error' });
          console.error(error);
        }
      }
    };

    fetchLocation();
  }, [id, enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateLocation(id, location);
        enqueueSnackbar('Location updated successfully', { variant: 'success' });
      } else {
        await createLocation(location);
        enqueueSnackbar('Location created successfully', { variant: 'success' });
      }
      navigate('/locations');
    } catch (error) {
      enqueueSnackbar('Failed to submit location', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Paper sx={{ padding: { xs: 2, sm: 4 }, margin: { xs: 2, sm: 4 } }}>
      <Typography variant="h6" gutterBottom>
        {id ? 'Edit Location' : 'Add New Location'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              required
              label="Location Name"
              name="name"
              value={location.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={location.description}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* Submit and Cancel Buttons */}
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Update Location' : 'Create Location'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/locations')}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default LocationForm;
